import { OnDestroy } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild  } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SignupService } from '../signup.service';
import { setting } from '../../../../../setting';
import { HomeService } from '../../home.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
	selector: 'agencies-form',
	templateUrl: './agencies.component.html',
	providers: [SignupService]
})

export class AgencyComponent implements OnInit, OnDestroy {
  @ViewChild('openModalErrorJoinUs') openModalErrorJoinUs: ElementRef;
  @ViewChild('closeButtonModalErrorSignup') closeButtonModalErrorSignup: ElementRef;
  @Input() sharedData: any;
  @Output() dataEvent = new EventEmitter<{}>();
  @Output() successEvent = new EventEmitter<void>();
  @Output() errorEvent = new EventEmitter<void>();
	public load : boolean;
	public form : FormGroup;
	public countries : any[];
  public destinations: any[] = [];
  public cities: any[] = [];
 	public getClientsSettingAppAuth: any;
  public response: boolean = true;
  public buttonError: boolean = false;

  public country_id: any;
  public code_phone: any;
  public employees: (number | string)[];
  private countryName: string;
  private countrySubscription: Subscription;

	constructor(
		  private _signupService: SignupService,
    	private fb : FormBuilder,
      private _homeService: HomeService
	           )

             {
              this.employees = Array.from({length: 31}, (_, i) => i);
              this.employees[30] = '+ 30';

              this.getClientsSettingAppAuth = JSON.parse(localStorage.getItem(setting.name));
              this.load = false;
              this.form = this.fb.group({
                Youaretype: new FormControl(1),
                TravelAgencyName : new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(60)]) ,
                City: new FormControl(null, Validators.required) ,
                Country: new FormControl(null, Validators.required),
                Destination: new FormControl(null, Validators.required),
                PhoneNumber: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]{6,}$/)]),
                PhoneCode: new FormControl(null),
                Website: new FormControl(null, [Validators.required, Validators.pattern("(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+")]),
                Contact: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(60)] ),
                EmailContact: new FormControl(null, [Validators.required,Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]),
                PercentageTravels: new FormControl(null, Validators.required),
                NumberEmployee:new FormControl(null, Validators.required) ,
                Comments: new FormControl(null, [Validators.maxLength(200)])
                });

	           }

  ngOnInit(): void {

                    this.getCountriesData();

                   }

  ngOnDestroy(): void {

                    this.countrySubscription.unsubscribe();

                      }

	onSubmit() {

                this.load = true; // loading screen
                this.buttonError = false;
                this.form.value.NumberEmployee = parseInt(this.form.value.NumberEmployee);
                const city = this.cities.find(c => Number(c.city_id) === Number(this.form.value.City));
                //this.logger.log(this.form.value);
                const payload = {
                  Youaretype: 1,
                  TravelAgencyName: this.form.value.TravelAgencyName,
                  City: this.form.value.City,
                  Country: this.form.value.Country,
                  CodeCountry: this.country_id,
                  Destination: this.form.value.Destination,
                  PhoneNumber: this.form.value.PhoneNumber,
                  PhoneCode: this.form.value.PhoneCode,
                  Website: this.form.value.Website,
                  Contact: this.form.value.Contact,
                  EmailContact: this.form.value.EmailContact,
                  PercentageTravels: this.form.value.PercentageTravels,
                  NumberEmployee: this.form.value.NumberEmployee,
                  Comments: this.form.value.Comments,
                  client_id: this.getClientsSettingAppAuth.id,
                  country_name: this.countryName,
                  city_name: city.city_name,

    }

console.log(JSON.stringify(payload))
		this._signupService.signup(payload).subscribe({
        next: (data: any) => {
          console.log(data)
          		this.load = false;
          		if(data.res == 1) {
                this.resetform();
                this.successEvent.emit(); // Emitir el evento de éxito
                // this.toastr.success(data.det);
          		} else {

          		}
        },
        error: (error: HttpErrorResponse) => {
          console.log(error)
          this.load = false;
          // this.toastr.error(JSON.parse(error._body).det,'Error');
          if(!this.buttonError) {
            this.buttonError = true;
            this.openModalErrorJoinUs.nativeElement.click();

            setTimeout(() => {
              this.closeButtonModalErrorSignup.nativeElement.click();
              this.errorEvent.emit();
            }, 1500);
          }
        }
      });
	}

  dataForm(value: any) {
    this.dataEvent.emit(value);
  }

  dataSelectForm(value: any) {
    this.dataEvent.emit(value);
  }

	resetform(){
    this.form.reset();
	}

  getCountriesData() {
    this.countrySubscription = this._homeService.getCountriesData().subscribe({
      next: (res: any) => {
        this.countries = res;
      },
      error: (error: HttpErrorResponse) => {
        console.error(error);
      }
    });
  }

  getDestinations(countryId: number) {
    this.destinations = [];
    this.cities = [];
    if(countryId) {
      this.country_id = countryId;
      this.countryName = this.countries.find(c => c.country_code === countryId).country_name;
      this.countrySubscription = this._homeService.getDestinationsData(countryId).subscribe({
        next: (res: any) => {
          this.destinations = res;
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
        }
      });

    }
  }

  getCities(destinationId: number) {
    this.countrySubscription = this._homeService.getCitiesData(destinationId).subscribe({
      next: (res: any) => {
        this.cities = res;
      },
      error: (error: HttpErrorResponse) => {
        console.error(error);
      }
    });
  }

  countryId(countryName: any) {
    this.country_id = this.countries.find(c => c.country_name === countryName);
    this.country_id = this.country_id.country_code;
  }

  codePhone(code_phone: any) {
    this.code_phone = code_phone;
  }

  webDetectError(input: any) {

    const webRegex = /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w-._~:/?#[\]@!$&'()*+,;=.]+/;

    const webValue = input.target.value;

    const isValidWeb = webRegex.test(webValue);

    if(!isValidWeb) {

      this.form.get('Website').setErrors({'errorWebsiteFormat': true});
    } else {

      this.form.get('Website').setErrors(null);
    }
  }

  emailInput(input: any) {
    // Expresión regular para validar el formato del correo electrónico
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Obtener el valor del correo electrónico del evento
    const emailValue = input.target.value;

    // Validar el formato del correo electrónico usando la expresión regular
    const isValidEmail = emailRegex.test(emailValue);

    // Si el correo electrónico no cumple con el formato deseado
    if (!isValidEmail) {
      // Establecer el error en el control del formulario
      this.form.get('EmailContact').setErrors({ 'errorEmailFormat': true });
    } else {
      // Si el correo electrónico cumple con el formato, eliminar cualquier error existente
      this.form.get('EmailContact').setErrors(null);
    }
   }

}
